import React, { useContext, useEffect, useMemo, useState } from 'react'

import { matchPath, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'

import ActivitiesPage from '_pages/people/[id]/activities'
import Affiliations from '_pages/people/[id]/affiliations'
import UserCompanies from '_pages/people/[id]/companies'
import ContactInfo from '_pages/people/[id]/info'
import ContactInfoEmails from '_pages/people/[id]/info/emails'
import ContactInfoPhones from '_pages/people/[id]/info/phones'
import UserInteractions from '_pages/people/[id]/interactions'
import UserIntroducers from '_pages/people/[id]/introducers'
import MarketDataPage from '_pages/people/[id]/market-data'
import CompanyMarketDataPage from '_pages/people/[id]/market-data/company'
import UserPeople from '_pages/people/[id]/people'
import Profile, { contributor } from '_pages/people/[id]/profile'
import SimilarPeople from '_pages/people/[id]/similar'
import SimilarPersonPage from '_pages/people/[id]/similar/[id]'
import Touchpoints from '_pages/people/[id]/touchpoints'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import Dashboard from '_core/components/Dashboard'
import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'
import PersonMarketData from '_core/components/market-data/PersonMarketData'
import PersonHeader from '_core/components/PersonHeader'
import PrivateRoute from '_core/components/PrivateRoute'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

import { usePersonMarketData } from '_core/hooks/usePersonMarketData'
import usePresetScroll from '_core/hooks/usePresetScroll'
import usePrivateFeatures from '_core/hooks/usePrivateFeatures'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { useSimilarPeople } from '_core/hooks/useSimilarPeople'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'

import { get } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type PersonProfileType = {
  isEnabled: boolean
  meUser: ProfileType
  marketDataIntegration: {
    enabled: boolean
    showSimilarPeople: boolean
    showSimilarCompanies: boolean
  }
  loading: boolean
  teamNumber: number
} & PersonType

const url = Paths._people

const PersonProfile = ({ teamNumber, ...props }: PersonProfileType) => {
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()
  const { queryParams } = useSearchQuery<{ name: string; email: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const [interactions, setInteractions] = useState<{ [key: string]: any } | null>()
  const matchProfile = matchPath(pathname, { path: Paths.personProfile })?.isExact
  const enableScroll = interactions !== undefined && !props.loading && !!props.PersonMd5 && matchProfile
  usePresetScroll(enableScroll)

  const enabledMarketData = props.marketDataIntegration?.enabled
  const prohibitedMarketData = typeof enabledMarketData === 'boolean' && !enabledMarketData
  const { name: mdName, email: mdEmail } =
    !props.loading && !props.PersonMd5 ? queryParams : { name: props.PersonNameText, email: props.BestEmailAddrText }
  const mdCompany = props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName
  const { marketData, getMarketData, error: marketDataError } = usePersonMarketData(mdName, mdEmail, mdCompany, true)
  const { similar, setSimilar, getPersonDetails, similarWidget, setSimilarWidget } = useSimilarPeople()
  const matchSimilarProfile = matchPath(pathname, { path: `${Paths.personProfile}/similar/:similarId` })?.isExact
  const matchCompanyMarketData = matchPath(pathname, { path: `${Paths.personProfile}/market-data/company/:companyId` })?.isExact
  const anotherMobileHeader = matchSimilarProfile || matchCompanyMarketData

  const { dataVisibility } = useUserDataVisibility([id])
  const { showInteractionsInProfile, hideColleagueRelationships } = dataVisibility || {}

  const { userActivitiesAccess } = usePrivateFeatures([id]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  useEffect(() => {
    if (!props.loading && enabledMarketData && !marketData) {
      getMarketData(teamNumber)
    }
  }, [enabledMarketData, marketData, props.loading, teamNumber])

  const dashboardLinks = (props: any) =>
    [
      {
        id: 'dashboard',
        name: 'Dashboard',
        link: `${url}/${id}`,
        condition: props.loading || props.MyUserKeyMd5 || props.PersonMd5,
        icon: ['far', 'th']
      },
      {
        id: 'introducers',
        name: 'Introducers',
        link: `${url}/${id}/introducers`,
        condition: !contributor(props),
        icon: ['far', 'address-book']
      },
      {
        id: 'people',
        name: 'People',
        link: `${url}/${props.MyUserKeyMd5}/people`,
        condition: contributor(props),
        icon: ['far', 'user']
      },
      {
        id: 'companies',
        name: 'Companies',
        link: `${url}/${props.MyUserKeyMd5}/companies`,
        condition: contributor(props),
        icon: ['far', 'building']
      },
      {
        id: 'interactions',
        name: 'Interactions',
        link: `${url}/${id}/interactions`,
        condition: props.loading || props.PersonMd5,
        icon: ['far', 'envelope']
      },
      {
        id: 'touchpoints',
        name: 'Touchpoints',
        link: `${url}/${id}/touchpoints`,
        condition: props.loading || props.PersonMd5,
        icon: ['far', 'handshake']
      },
      {
        id: 'affiliations',
        name: 'Affiliations',
        link: `${url}/${id}/affiliations`,
        condition: props.loading || props.PersonMd5,
        icon: ['far', 'suitcase']
      },
      {
        id: 'contactinfo',
        name: 'Contact Information',
        link: `${url}/${id}/info`,
        condition: props.loading || props.PersonMd5,
        icon: ['far', 'address-card']
      }
    ].filter((items) => (items.id === 'interactions' ? showInteractionsInProfile : true))

  React.useEffect(() => {
    if (!anotherMobileHeader && queryParams.name) {
      setMobileHeader(queryParams.name)
    }
    if (!anotherMobileHeader && !queryParams.name && !props.loading) setMobileHeader(props.PersonNameText || '')
  }, [props.PersonNameText, setMobileHeader, anotherMobileHeader, queryParams.name, props.loading])

  React.useEffect(() => {
    return () => {
      setInteractions(undefined)
      setSimilarWidget(null)
    }
  }, [props.PersonMd5])

  React.useEffect(() => {
    const getInteractions = async () => {
      setInteractions(await get(`/interactions?TeamNumber=${teamNumber}&personMd5=${props.PersonMd5}`))
    }

    if (matchProfile && !props.loading && interactions === undefined) {
      if (showInteractionsInProfile) {
        getInteractions()
      } else {
        setInteractions(null)
      }
    }
  }, [props.PersonMd5, showInteractionsInProfile, props.loading, teamNumber, matchProfile, interactions])

  if (!props.loading && !props.PersonMd5) {
    return queryParams.name ? (
      <>
        {enabledMarketData && !marketDataError && queryParams.email ? (
          <PersonMarketData person={marketData} />
        ) : (
          <>
            <Widget scope="none">
              <UnknownProfileSummary title={queryParams.name} userKey={queryParams.email} loading={props.loading} />
            </Widget>
            <Widget scope="list">
              <Empty title="Profile not found in DotAlign" />
            </Widget>
          </>
        )}
      </>
    ) : (
      <Empty title="Profile not found" />
    )
  }

  const interactionsHeight = dashboardLinks(props).filter((link: any) => link.condition).length * 78

  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`}>
          <PersonHeader {...props} marketData={marketData} />
        </Route>

        <Route exact path={`${url}/:id/*`}>
          <Wide>
            <PersonHeader {...props} marketData={marketData} />
          </Wide>
        </Route>
      </Switch>

      <Dashboard {...props} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:id`}>
            <Profile
              interactions={interactions}
              marketData={marketData}
              similar={similarWidget}
              setSimilar={setSimilarWidget}
              showInteractionsInProfile={showInteractionsInProfile}
              hideColleagueRelationships={hideColleagueRelationships}
              showActivityStats={!!isStatsWidgetVisible}
              isDetailedActivityFromStatsWidgetVisible={!!isDetailedActivityFromStatsWidgetVisible}
              {...props}
            />
          </Route>

          <PrivateRoute
            hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
            exact
            path={`${url}/:id/activities`}
            component={ActivitiesPage}
          />
          <Route exact path={`${url}/:id/companies`} component={UserCompanies} />
          <Route exact path={`${url}/:id/people`} component={UserPeople} />
          <Route exact path={`${url}/:id/introducers`} component={UserIntroducers} />
          <Route exact path={`${url}/:id/info/phones`} render={() => <ContactInfoPhones name={props.PersonNameText} />} />
          <Route exact path={`${url}/:id/info/emails`} render={() => <ContactInfoEmails name={props.PersonNameText} />} />
          <Route exact path={`${url}/:id/info`} render={() => <ContactInfo name={props.PersonNameText} />} />
          <Route
            exact
            path={`${url}/:id/affiliations`}
            render={() => (
              <DynamicEntity
                url={`/personjobs/${id}?TeamNumber=${teamNumber}`}
                component={Affiliations}
                list={true}
                infinite={true}
                forceNarrow={true}
                nativeBack
                subHeader={(props: any) => (
                  <Typography title={`Affiliations · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap ellipsis>
                    Affiliations · {props.total_item_count}
                  </Typography>
                )}
                id="person_affiliations"
              />
            )}
          />

          <Route
            exact
            path={`${url}/:id/touchpoints`}
            render={() => (
              <DynamicEntity
                url={`/people/${id}?teamNumber=${teamNumber}&includeStats=true`}
                component={Touchpoints}
                nativeBack
                subHeader={() => (
                  <Typography title="Key touch points" variant="body1" style={{ maxWidth: '100%' }} noWrap ellipsis>
                    Key touch points
                  </Typography>
                )}
                id="person_touchpoints"
              />
            )}
          />

          <Route exact path={`${Paths._people}/:id/market-data`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <MarketDataPage marketData={marketData} error={marketDataError} />}
          </Route>

          <Route exact path={`${url}/:id/similar`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <SimilarPeople
                BestEmailAddrText={props.BestEmailAddrText}
                BestJobTitleText={props.BestJobTitleText || ''}
                marketData={marketData}
                similar={similar || similarWidget}
                setSimilar={setSimilar}
                loading={props.loading}
                teamNumber={teamNumber}
              />
            )}
          </Route>

          <Route exact path={`${url}/:id/similar/:similarId`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <SimilarPersonPage getPersonDetails={getPersonDetails} teamNumber={teamNumber} />
            )}
          </Route>

          <Route exact path={`${url}/:id/market-data/company/:companyId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <CompanyMarketDataPage teamNumber={teamNumber} />}
          </Route>

          <PrivateRoute hasAccess={showInteractionsInProfile} exact path={`${url}/:id/interactions`}>
            <UserInteractions minHeight={interactionsHeight} PersonMd5={props.PersonMd5} />
          </PrivateRoute>
        </Switch>
      </Dashboard>
    </>
  )
}

const PersonPage = () => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  useEffect(() => {
    setMobileHeader('', true)
  }, [id, setMobileHeader])

  const memoUrls = useMemo(
    () => [
      {
        key: 'profile',
        url: `/people/${id}?teamNumber=${teamNumber}&includeTeamMembership=true&numCompanies=4&numUserContacts=4&numIntroducers=8&numAliases=5&numJobs=4&numPhones=2&numEmails=2&includeStats=true&sortBy=ScoreDesc&WithPersonTags=true`,
        merge: true,
        single: true
      },
      {
        key: 'salesforce',
        url: '/usersettings/salesforce',
        merge: true
      },
      {
        key: 'meUser',
        url: '/me/profile'
      },
      {
        key: 'marketDataIntegration',
        url: '/usersettings/marketDataIntegration'
      }
    ],
    [id, teamNumber]
  )

  return (
    <Page>
      <DynamicEntity<{ extraProps: { addprops: Pick<PersonProfileType, 'teamNumber'> } }>
        urls={memoUrls}
        component={PersonProfile}
        addprops={{ teamNumber }}
        keepMounted
        id="person_profile"
      />
    </Page>
  )
}

export default PersonPage
