import React, { useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import Empty from '_core/components/Empty'
import ContactItem, { ContactItemProps } from '_core/components/introductions/ContactItem'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const TargetedContacts = (props: {
  total: number
  loading: boolean
  items: IntroductionContactResp[]
  statuses: { id: number; value: string; label: string }[]
  querierHasWriteAccess: boolean
  closedOut: boolean
}) => {
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader(`Identified people ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  const items: ContactItemProps[] = props.items.map((item) => ({
    name: item.displayAs,
    email: item.contactEmail,
    statusLabel: props.statuses.find((status) => status.value === item.targetingStatus)?.label || '',
    status: item.targetingStatus,
    querierHasWriteAccess: props.querierHasWriteAccess,
    closedOut: props.closedOut,
    link: `${Paths._introductions}/${id}/contacts/${item.contactEmail}`
  }))

  return (
    <Widget scope="card">
      <Repeater
        variant="card"
        direction="vertical"
        component={ContactItem}
        skeleton={{ size: 5, loading: props.loading }}
        empty={
          <Box mt={2}>
            <Empty subTitle="No contacts" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users']} style={{ color: '#A7A7A7' }} />} close={false} />
          </Box>
        }
        items={items || []}
      />
    </Widget>
  )
}

export default TargetedContacts
